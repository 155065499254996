import request from "@/utils/request";

// 获取任务历史列表
export function TaskRecord(params) {
  return request({
    url: "/admin/base/taskRecord",
    method: "get",
    params
  });
}

